<template>
  <div class="hello">
    <h1>Konzert: Voyage Vocal</h1>
    <p>Danke an alle, die da waren!</p>
    <img class="fullwidth" src="https://chantiervocal-public.s3.amazonaws.com/Plakat_Voyage_Vocal.webp" alt="Plakat Voyage Vocal" />
  </div>
</template>

<script>
export default {
  name: 'FrontPage',
  data () {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
  <div>
    <p>Die Anmeldung funktioniert ohne Passwort – du bekommst einen Login-Link zugeschickt.</p>
    <sync-form v-bind="formdata">
      <div>
        <label for="user">Email</label>
        <input type="email" v-model="email">
      </div>
    </sync-form>
  </div>
</template>

<script>
import SynchronousForm from '@/components/Generic/SynchronousForm'

export default {
  name: 'LoginPage',
  components: {
    'sync-form': SynchronousForm
  },
  data: function () {
    return {
      email: '',
    }
  },
  computed: {
    payload: function () {
      return '"' + this.email.trim().toLowerCase() + '"'
    },
    formdata: function () {
      return {
        endpoint: '/sendLoginLink',
        submitText: 'Login',
        payload: this.payload,
        successMsg: 'Alles klar! Du hast eine Email bekommen.',
      }
    },
  }
}
</script>

<style scoped>
form {
  max-width: 20em;
}
</style>

<template>
  <div>
    <h1>Feedback</h1>
    <p>Hi, schön dass du die Webseite testest. Hier ist ein Überblick, welche Funktionen schon da sind und welche noch fehlen:</p>
    <ul>
      <li><s>Benutzerverwaltung</s></li>
      <li><s>Passwort ändern und vergessen</s></li>
      <li><s>Ein benutzbares Design</s></li>
      <li><s>Emails verschicken</s></li>
      <li><s>Klaus</s></li>
      <li><s>Probenverwaltung</s></li>
      <li><s>Feedback</s></li>
      <li><s>Impressum</s></li>
      <li><s>Inhalte für öffentliche Seiten und Medienarchiv</s></li>
      <li><s>Interne Mediengalerie</s></li>
      <li><s>Noten hier anstatt auf Dropbox</s></li>
      <li><s>Umzug auf chantiervocal.de</s></li>
      <li>Öffentliche Mediengalerie</li>
      <li>Ein Archiv aller Chorvorstandsmails auf einen Blick</li>
      <li>Eine Art Blog für die News-Seite</li>
      <li>Schöneres Design, neues Logo</li>
      <li>Link auf unsere FB-Seite</li>
    </ul>
    <h2>Was für Feedback ist wichtig?</h2>
    Schreibt mir ...
    <ul>
      <li>Ideen für Funktionen, die noch nicht oben stehen</li>
      <li>wenn ihr <a href="#" v-on:click="errorExample">so einen Dialog</a> seht, weil das sollte nicht passieren</li>
      <li>wenn ihr unhilfreiche Fehlermeldungen bekommt</li>
      <li>wenn ihr Sicherheitslücken entdeckt (dass ihr Mails verschicken könnt, ist allerdings Absicht)</li>
      <li>was ihr an der Webseite anders designen würdet, und wie</li>
    </ul>
    <sync-form v-bind="formdata" v-on:ok="ok">
      <div>
        <label>Feedback</label>
        <textarea v-model="body"></textarea>
      </div>
    </sync-form>
  </div>
</template>

<script>
import SynchronousForm from '@/components/Generic/SynchronousForm'

export default {
  name: 'FeedbackPage',
  components: {
    'sync-form': SynchronousForm
  },
  data () {
    return {
      body: ''
    }
  },
  methods: {
    ok: function () {
      this.body = ''
    },
    errorExample: function () {
      console.log('??')
      alert('Beispiel')
    }
  },
  computed: {
    payload: function () {
      let recipients = {
        tag: 'Users',
        contents: [1]
      }
      let formData = new FormData()
      formData.append('recipients', JSON.stringify(recipients))
      formData.append('subject', 'Feedback Chorwebseite')
      formData.append('body', this.body)
      return formData
    },
    formdata: function () {
      return {
        endpoint: '/mail/sendNormal',
        submitText: 'Senden',
        payload: this.payload,
        successMsg: 'Gesendet'
      }
    }
  }
}
</script>

<style>
</style>